<template>
	<v-alert
		v-if="currentExerciseId"
		color="#3370EA"
		type="success"
		prominent
		class="alert-banner text-left animate__animated animate__backInUp"
	>
		<v-row align="center">
			<v-col class="grow">
				<strong class="fs20 text-alert-banner">Ta formation est prête !</strong>
			</v-col>
			<v-col class="shrink">
				<v-btn
					color="white"
					@click="goToExercise()"
					class="white black--text animate__animated animate__pulse animate__faster animate__infinite"
				>
					COMMENCER
				</v-btn>
			</v-col>
		</v-row>
	</v-alert>
</template>

<script>
export default {
	name: 'FloatingComponentToFirstExercise',
	data() {
		return {
			currentExerciseId: null,
		};
	},
	async mounted() {
		const res = await this.$http.get(`/api/formation/${this.$route.params.idFormation}/current-exercise-id`);
		this.currentExerciseId = res.data.currentExerciseId;
	},
	methods: {
		goToExercise() {
			const routeData = this.$router.resolve(
				'/exercices/' + this.$route.params.idFormation + '/' + this.currentExerciseId
			);

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('testPositionnement-goToExercise');
			}

			window.open(routeData.href, '_blank');
		},
	},
};
</script>
<style scoped>
.alert-banner {
	margin-left: 5px;
	margin-right: 5px;
	position: -webkit-sticky;
	position: sticky;
	top: 10px;
	bottom: 10px;
	z-index: 10000;
}

@media only screen and (max-width: 600px) {
	.alert-banner {
		top: 10px;
		bottom: 10px;
	}
	.text-alert-banner {
		/* overwrite the fs20 on desktop */
		font-size: 15px !important;
	}
}
</style>
