<template>
	<div class="ortho-results-container">
		<div v-if="isHidden">
			<!-- hidded because useless
			<v-btn outlined large class="hvr-shrink" @click="isHidden = false">
				<v-icon class="ortho-icon-black fs15">fas fa-arrow-left</v-icon>
				<span class="mr5">Revenir à la page précédente</span>
			</v-btn> -->
			<ResultsDetail
				:score="score"
				:userFirstName="userFirstName"
				:listQuestions="listQuestions"
				:answersGiven="answersGiven"
				:goodAnswersGiven="goodAnswersGiven"
				:badAnswersGiven="badAnswersGiven"
				:trial="trial"
			></ResultsDetail>
		</div>

		<div v-if="!isHidden">
			<v-card
				class="mt20 mx-auto shadow-blue"
				max-width="600"
				:class="{
					pa7: $vuetify.breakpoint.smAndDown,
					pa60: $vuetify.breakpoint.mdAndUp,
				}"
			>
				<div class="mb20">
					<img src="@/assets/icons/check.svg" class="mt20 check-icon-results" alt="" />
				</div>
				<div class="mb20">
					<div class="ortho-results-score-title ortho-bold">
						<span>Ton parcours a été <br />personnalisé avec succès</span>
					</div>
				</div>
				<div class="mb20 mt20">
					<div class="ortho-results-text mb20 pr-10 pl-10">
						<span class="ortho-bold ortho-text-green">Félicitations</span>, ta première session est prête !
						<!-- <br /> te sera envoyée dès {{ dateDisplay }} matin -->
					</div>
				</div>
				<v-btn color="black" dark large :loading="loading" @click="goToTPResultPage()" class="hvr-shrink mt20 mb20">
					Mon rapport personnel
					<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
				</v-btn>
			</v-card>
		</div>
	</div>
</template>

<script>
import ResultsDetail from './ResultsDetail.vue';
// import moment from "moment";

const TIME_LOADER_BUTTON = 6000; //ms

export default {
	name: 'FinishQuiz',
	props: {
		score: Number,
		userFirstName: String,
		listQuestions: Array,
		answersGiven: Array,
		goodAnswersGiven: Array,
		badAnswersGiven: Array,
		trial: String,
	},
	components: {
		ResultsDetail,
	},
	computed: {
		//  Comment because may use later
		// dateDisplay() {
		//   if (moment().format("e") == 5 || moment().format("e") == 6) {
		//     return "lundi";
		//   } else {
		//     return "demain";
		//   }
		// },
	},
	data() {
		return {
			loading: true,
			isHidden: false,
			jaugeObject: {
				ortho: 0,
				grammaire: 0,
				aisance: 0,
			},
		};
	},
	mounted: function () {
		//loading on button see rapport perso
		setTimeout(() => {
			this.loading = false;
		}, TIME_LOADER_BUTTON);
	},
	methods: {
		goToTPResultPage() {
			this.isHidden = true;
			location.reload();
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('testPositionnement-goToResultsPage');
			}
		},
	},
};
</script>
