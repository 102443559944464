<template>
	<TestPositionnementComponent />
</template>

<script>
import TestPositionnementComponent from '@/components/positionnement/Index.vue';

export default {
	name: 'TestPositionnement',
	components: {
		TestPositionnementComponent,
	},
};
</script>
