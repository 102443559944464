<template>
	<div style="height: 100%">
		<div v-if="!error" style="height: 100%">
			<ResultsDetail
				v-if="step === 'made'"
				:listQuestions="listQuestions"
				:userFirstName="userFirstName"
				:score="score"
				:answersGiven="answersGiven"
				:goodAnswersGiven="goodAnswersGiven"
				:badAnswersGiven="badAnswersGiven"
				:trial="trial"
				class="container"
			></ResultsDetail>
			<ResetPasswordComponent v-else-if="step === 'resetPassword'" @clicked="onClickFromRP"></ResetPasswordComponent>
			<LoginComponent v-else-if="step === 'login'" @clicked="onClickFromRP"></LoginComponent>
			<InfoBeforeStarting
				v-else-if="step === 'info'"
				@clicked="beginQuestionnaire"
				:userFirstName="userFirstName"
			></InfoBeforeStarting>
			<QuestionnaireComponent
				v-else-if="step === 'questionnaire'"
				:listQuestions="listQuestions"
				:userFirstName="userFirstName"
			></QuestionnaireComponent>
		</div>
		<Error v-else :error="error"></Error>
	</div>
</template>

<script>
import ResetPasswordComponent from '../ResetPassword';
import LoginComponent from '../Login';
import InfoBeforeStarting from './InfoBeforeStarting';
import QuestionnaireComponent from './Questionnaire';
import Error from '../../views/layouts/Error';
import ResultsDetail from './ResultsDetail';

export default {
	data() {
		return {
			step: '',
			listQuestions: [],
			userFirstName: '',
			score: 0,
			answersGiven: [],
			goodAnswersGiven: [],
			badAnswersGiven: [],
			trial: '',
			error: false,
		};
	},
	name: 'TestPositionnement',
	components: {
		ResetPasswordComponent,
		ResultsDetail,
		LoginComponent,
		InfoBeforeStarting,
		QuestionnaireComponent,
		Error,
	},
	async created() {
		try {
			const res = await this.$http.get(
				'/api/positionnement-quiz/' + this.$route.params.idUser + '/' + this.$route.params.idFormation
			);

			if (res.data.positionnementInfos.score_test != null) {
				//redirect test already done page
				this.step = 'made';
				this.score = res.data.positionnementInfos.score_test;
				this.goodAnswersGiven = res.data.positionnementInfos.user_good_answers_test;
				this.badAnswersGiven = res.data.positionnementInfos.user_wrong_answers_test;
				this.answersGiven = res.data.positionnementInfos.user_answers_test;
				this.trial = res.data.positionnementInfos.test_trial;
			} else {
				if (this.$route.meta.from) {
					this.step = 'questionnaire';
				} else {
					this.step = 'info';
				}
			}
			this.userFirstName = res.data.userInfos.first_name;
			this.listQuestions = res.data.positionnementInfos.positionnement_test_id.questions_id;
		} catch (err) {
			this.error = true;
		}
		if (process.env.VUE_APP_MIXPANEL == 'production') {
			this.$mixpanel.track('testPositionnement-start');
		}
	},
	methods: {
		onClickFromRP(value) {
			if (value == 'positionnementTest') {
				this.step = 'info';
			}
		},
		beginQuestionnaire(value) {
			if (value == 'startTest') {
				this.step = 'questionnaire';
			}
		},
	},
};
</script>
